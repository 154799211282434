<template>
  <b-overlay :show="commentBusy">
    <!--Begin:: App Content-->
    <div class="kt-grid__item kt-grid__item--fluid kt-app__content mb-3" id="kt_chat_content">
      <div class="kt-chat">
        <div class="kt-portlet kt-portlet--head-lg kt-portlet--last">
          <div class="kt-portlet__head">
            <div class="kt-chat__head ">
              <div class="kt-chat__left"></div>
              <div class="kt-chat__center">
                <div class="kt-chat__label mb-1">
                  <a class="kt-chat__title cursor-default">{{ film_title || 'نامەلۇم' }}</a>
                </div>
                <div class="kt-chat__pic kt--hidden">
                  <b-avatar-group>
                    <b-avatar v-for="comment in film_comments_list" :key="comment.id" class="kt-media kt-media--sm kt-media--circle" :src="comment.commenter.avatar" :title="comment.commenter.nickname" v-b-tooltip></b-avatar>
                  </b-avatar-group>
                </div>
              </div>
              <div class="kt-chat__right"></div>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div v-for="comment in film_comments_list" :key="comment.id" @click="onClickComment(comment.id)" class="commenter-item d-flex px-3 py-3 mb-2">
              <div class="comment-content mr-2 text-truncate">{{ comment.comment }}</div>
              <div class="comment-time-ago mr-2">{{ comment.created_at }}</div>
              <b-avatar class="comment-commenter-avatar" size="30px" :src="comment.commenter.avatar" :title="comment.commenter.nickname" v-b-tooltip rounded="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination :meta="film_comments_meta" :implementSearch="false" @refresh="onRefresh" @change-page="onChangePage" />
    <!--End:: App Content-->
  </b-overlay>
</template>

<script>
  import pagination from "@v@/components/paginate";
  import commentMixin from "@m@/comment.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: {pagination},
    mixins: [commentMixin, formBusyMixin],
    computed: {
      ...mapGetters(['film_comments_list', 'film_comments_meta', 'film_title', 'comments_error'])
    },
    data(){
      return {
        commentBusy: false,
        comments: [
          {
            comment: `ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى`,
            time_ago: `5 مىنۇت ئىلگىرى`,
            avatar: `assets/media/users/100_4.jpg`,
            nickname: `پالانى`,
            id: 1
          },
          {
            comment: `ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى، ئىنكاس مەزمۇنى`,
            time_ago: `10 مىنۇت ئىلگىرى`,
            avatar: `assets/media/users/300_11.jpg`,
            nickname: `پۇكۇنى`,
            id: 2
          }
        ]
      }
    },
    props: {
      id: {
        type: [String, Number]
      }
    },
    created() {
      this.getFilmComments(this.id);
    },
    watch:{
      "id": {
        handler(v, ov){
          if( v !== ov ){
            this.getFilmComments(v);
          }
        }
      }
    },
    methods: {
      onClickComment(comment_id){
        this.$router.push({name: 'comment.detail', params: { id: comment_id }})
      },
      onChangePage(e){
        let { page } = e;
        this.getFilmComments(this.id, page);
      },
      onRefresh(){
        this.getFilmComments(this.id, 1);
      },
    }
  }
</script>

<style scoped>
  .b-avatar-group {
    display: flex;
    justify-content: center;
  }

  .kt-app .kt-app__content, .kt-app .kt-app__detail {
    margin-right: unset !important;
  }

  .commenter-item {
    height: 40px;
    align-items: center;
    box-sizing: content-box;
    border-radius: 5px;
  }

  .commenter-item:hover {
    background-color: #f8f9fa !important;
  }

  .commenter-item:last-child {
    margin-bottom: unset !important;
  }

  .commenter-item .comment-content {
    flex-grow: 1;
  }

  .commenter-item .comment-time-ago {
    width: 100px;
  }

</style>
